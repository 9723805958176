<template>
  <div class="reports">
    <template v-if="workflowId">
      <BaseScrollbar height="calc(100vh - 265px)">
        <!-- child routes -->
        <div id="search">
          <!-- pie chart -->
          <div class="row items-start q-col-gutter-sm q-my-sm">
            <template v-for="(item, index) in workflowCount">
              <template v-if="item.visible">
                <div
                  :key="'workflowcount' + workflowId + '' + index"
                  class="col"
                >
                  <div class="chart chartBox">
                    <div class="row text-capitalize items-center header">
                      <div class="col">
                        {{ item.workflowStatus }}
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          icon="mdi-pin"
                          :color="item.dashboard ? 'secondary' : 'gray'"
                          no-border
                          is-flat
                          size="20px"
                          rotate="rotate-45"
                          @click.stop="
                            item.dashboard
                              ? deleteItem(item, 'reportCount')
                              : saveFilter(item, 'reportCount')
                          "
                        />
                      </div>
                    </div>
                    <div class="column">
                      <template v-if="item.matchingKey === 'totalRequests'">
                        <div
                          class="text-primary col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'completedRequests'">
                        <div
                          class="text-primary col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template
                        v-if="item.matchingKey === 'incompleteRequests'"
                      >
                        <div
                          class="text-orange col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaOverdue'">
                        <div
                          class="text-red col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaProcessing'">
                        <div
                          class="text-green col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaAchieved'">
                        <div
                          class="text-green col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'forward'">
                        <div
                          class="text-secondary col self-center q-mt-md q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <!-- pie chart -->
        </div>
        <!-- child routes -->

        <div v-if="false" class="workflowCharts q-mb-sm">
          <div class="row border-bottom">
            <div class="text-bold q-pa-sm q-mr-md">Data Link Chart</div>
            <template v-for="(field, index) in sankeyChartsFilter">
              <div :key="field.id" class="col-auto">
                <div class="row">
                  <div class="col-auto">
                    <SelectField
                      v-model="sankeyChartsFilter[index]"
                      :options="formFields"
                      :is-clearable="false"
                      class="fieldList q-mb-sm"
                      @input="overviewChart"
                    >
                    </SelectField>
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'remove filter'"
                      icon="mdi-close"
                      class="plus q-mx-sm"
                      size="14px"
                      is-flat
                      color="red"
                      @click="removeSankeyChartsFilter(index)"
                    />
                  </div>
                </div>
              </div>
            </template>

            <BaseActionButton
              v-tooltip.top="'add filter'"
              icon="mdi-plus"
              color="secondary"
              class=""
              @click="addSankeyChartsFilter"
            />
          </div>
          <div class="row chart">
            <vue-highcharts
              ref="refCharts"
              :highcharts="Highcharts"
              :options="sankeyCharts"
              style="width: 100%"
            ></vue-highcharts>
          </div>
        </div>

        <div class="row">
          <div class="col-6 q-pr-sm q-mb-sm">
            <div class="chart">
              <div class="row item-center q-body q-mb-sm">
                <div
                  class="col-12 q-mr-xs cursor-pointer header row"
                  :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.month = '';
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(workflowId);
                  "
                >
                  <span class="col">Month</span>

                  <span class="col-auto q-pa-sm">
                    <BaseActionButton
                      icon="mdi-pin"
                      :color="pinedchart ? 'secondary' : 'gray'"
                      no-border
                      is-flat
                      size="20px"
                      rotate="rotate-45"
                      @click.stop="
                        pinedchart
                          ? deleteItem(chartItem, 'fullchart')
                          : saveFilter(chartItem, 'fullchart')
                      "
                    />
                  </span>
                </div>
                <div
                  v-if="chartDataDrilldownStage.month"
                  class="col-auto cursor-pointer header"
                  :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(
                      workflowId,
                      chartDataDrilldownStage.month
                    );
                  "
                >
                  / {{ chartDataDrilldownStage.month }}
                </div>
                <div
                  v-if="chartDataDrilldownStage.week"
                  class="col-auto q-ml-xs header"
                  @click="chartDataDrilldownStage.week = ''"
                >
                  / {{ chartDataDrilldownStage.week }}
                </div>
              </div>
              <vue-highcharts
                ref="refWorkflowChart"
                :highcharts="Highcharts"
                :options="chartData"
              ></vue-highcharts>
            </div>
          </div>

          <template v-if="stageCount.length">
            <div id="stage" class="col-6 q-pr-sm q-mb-sm">
              <div
                class="font-bold q-pa-sm row"
                style="border-bottom: 1px solid var(--divider-color)"
              >
                <span class="col">Workflow Stage Distribution</span>

                <span class="col-auto">
                  <BaseActionButton
                    icon="mdi-pin"
                    :color="stagePin ? 'secondary' : 'gray'"
                    no-border
                    is-flat
                    size="20px"
                    rotate="rotate-45"
                    @click.stop="
                      stagePin
                        ? deleteItem(stageItem, 'stage')
                        : saveFilter(stageItem, 'stage')
                    "
                  />
                </span>
              </div>

              <table id="stageTable" class="q-mt-sm">
                <thead>
                  <tr>
                    <!-- columns -->

                    <th v-for="column in stageColumns" :key="column.id">
                      <template v-if="column.type === 'ICON'">
                        <BaseIcon :name="column.label" inherit-color />
                      </template>
                      <template v-else>
                        {{ column.label }}
                      </template>
                    </th>

                    <!-- ... -->
                  </tr>
                </thead>

                <tbody>
                  <template v-for="item in stageCount">
                    <tr :key="item.id">
                      <!-- columns -->

                      <td v-for="column in stageColumns" :key="column.id">
                        <slot
                          :name="column.name"
                          :value="item[column.name]"
                          :item="item"
                        >
                          <template v-if="column.name === 'icon'">
                            <BaseIcon
                              :name="item[column.name]"
                              :color="item.color"
                            />
                          </template>
                          <template v-else>
                            {{ item[column.name] }}
                          </template>
                        </slot>
                      </td>

                      <!-- ... -->
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </template>

          <div
            v-for="(item, index) in barChartData"
            :key="'barchart' + index"
            class="col-4 q-pr-sm q-pb-sm"
            style="height: 350px"
          >
            <BarChart
              :title="item.key"
              :x-data="item.xAxis"
              :y-data="item.yAxis"
              :is-horizontal="true"
              :is-stacked="true"
              :options="barChartOptions"
              :dashboard="item.dashboard"
              @pin="saveFilter(item, 'bar')"
              @isDelete="deleteItem(item, 'bar')"
            />
          </div>

          <div
            v-for="(item, key) in pieChartData"
            :key="'piechart' + key"
            class="col-4 q-pr-sm q-pb-sm"
            style="height: 350px"
          >
            <PieChart
              :title="item.name"
              :x-data="item.xAxis"
              :y-data="item.yAxis"
              :options="pieChartOptions"
              :dashboard="item.dashboard"
              @pin="saveFilter(item, 'Pie')"
              @isDelete="deleteItem(item, 'Pie')"
            />
          </div>

          <template v-if="userCount.length">
            <div id="user" class="col-6 q-pl-sm q-mb-sm">
              <table id="userTable">
                <thead>
                  <tr>
                    <!-- columns -->

                    <th v-for="column in userColumns" :key="column.id">
                      {{ column.label }}
                    </th>

                    <!-- ... -->
                  </tr>
                </thead>

                <tbody>
                  <template v-for="item in userCount">
                    <tr :key="item.id">
                      <!-- columns -->

                      <td v-for="column in userColumns" :key="column.id">
                        <slot
                          :name="column.name"
                          :value="item[column.name]"
                          :item="item"
                        >
                          <template>
                            {{ item[column.name] }}
                          </template>
                        </slot>
                      </td>

                      <!-- ... -->
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </template>

          <template v-if="timeChartyData.length">
            <div class="col-6 q-pr-sm q-pb-sm">
              <BarChart
                title="Average Time on SLA"
                :x-data="timeChartxData"
                :y-data="timeChartyData"
                :is-horizontal="true"
                :is-stacked="true"
                :options="options"
              />
            </div>
          </template>
        </div>
      </BaseScrollbar>
    </template>

    <template v-else>
      <BaseScrollbar height="calc(100vh - 265px)">
        <!-- child routes -->
        <div id="search">
          <!-- pie chart -->
          <div class="row items-start q-col-gutter-sm q-my-sm">
            <template v-for="(item, index) in workflowCount">
              <template v-if="item.visible">
                <div :key="'overallcount' + index" class="col">
                  <div class="chart chartBox">
                    <div class="row text-capitalize items-center header">
                      <div class="col">
                        {{ item.workflowStatus }}
                      </div>

                      <div class="col-auto">
                        <BaseActionButton
                          icon="mdi-pin"
                          :color="item.dashboard ? 'secondary' : 'gray'"
                          no-border
                          is-flat
                          size="20px"
                          rotate="rotate-45"
                          @click.stop="
                            item.dashboard
                              ? deleteItem(item, 'reportCount')
                              : saveFilter(item, 'reportCount')
                          "
                        />
                      </div>
                    </div>
                    <div class="column">
                      <template v-if="item.matchingKey === 'totalRequests'">
                        <div
                          class="text-primary col self-center q-mt-lg q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'completedRequests'">
                        <div
                          class="text-primary col self-center q-mt-lg q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template
                        v-if="item.matchingKey === 'incompleteRequests'"
                      >
                        <div
                          class="text-orange col self-center q-mt-lg q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaOverdue'">
                        <div
                          class="text-red col self-center q-mt-lg q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaProcessing'">
                        <div
                          class="text-green col self-center q-mt-lg q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'slaAchieved'">
                        <div
                          class="text-green col self-center q-mt-lg q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                      <template v-if="item.matchingKey === 'forward'">
                        <div
                          class="text-secondary col self-center q-mt-lg q-mb-sm count"
                        >
                          {{ item.totalCount }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <!-- pie chart -->
        </div>
        <!-- child routes -->

        <div class="row">
          <div class="col-6 q-pr-sm q-mb-sm">
            <div class="chart">
              <div class="row item-center q-body q-mb-sm">
                <div
                  class="col-12 row q-mr-xs cursor-pointer header"
                  :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.month = '';
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(workflowId);
                  "
                >
                  <span class="col">Month</span>

                  <span class="col-auto q-pa-sm">
                    <BaseActionButton
                      icon="mdi-pin"
                      :color="pinedchart ? 'secondary' : 'gray'"
                      no-border
                      is-flat
                      size="20px"
                      rotate="rotate-45"
                      @click.stop="
                        pinedchart
                          ? deleteItem(chartItem, 'fullchart')
                          : saveFilter(chartItem, 'fullchart')
                      "
                    />
                  </span>
                </div>
                <div
                  v-if="chartDataDrilldownStage.month"
                  class="col-auto cursor-pointer header"
                  :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(
                      workflowId,
                      chartDataDrilldownStage.month
                    );
                  "
                >
                  / {{ chartDataDrilldownStage.month }}
                </div>
                <div
                  v-if="chartDataDrilldownStage.week"
                  class="col-auto q-ml-xs header"
                  @click="chartDataDrilldownStage.week = ''"
                >
                  / {{ chartDataDrilldownStage.week }}
                </div>
              </div>
              <vue-highcharts
                ref="refWorkflowChart"
                :highcharts="Highcharts"
                :options="chartData"
              ></vue-highcharts>
            </div>
          </div>
        </div>
      </BaseScrollbar>
    </template>
    <UnpinDashboard
      v-model="isDeleteConfirmationVisible"
      :delete-data="deleteData"
      :label="deleteName"
      @delete="success = true"
    />
  </div>
</template>

<script>
import BarChart from "@/components/common/charts/BarChart.vue";
import PieChart from "@/components/common/charts/PieChart.vue";
// import Exporting from "highcharts/modules/exporting.js";
import Drilldown from "highcharts/modules/drilldown.js";
import { workflow, report, dashboard } from "@/api/factory.js";
import { Chart, LinearScale } from "chart.js";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import VueHighcharts from "vue2-highcharts";
import Highcharts from "highcharts";
import Exporting from "../../../../../../../node_modules/highcharts/modules/sankey.js";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import UnpinDashboard from "@/components/common/display/item-actions/components/UnpinDashboard.vue";

Drilldown(Highcharts);
Exporting(Highcharts);

Chart.register(LinearScale, SankeyController, Flow);
export default {
  name: "OverallChart",

  components: {
    BarChart,
    VueHighcharts,
    PieChart,
    SelectField,
    UnpinDashboard,
  },

  props: {
    workflowId: {
      type: Number,
      default: 0,
    },

    workflowStage: {
      type: Array,
      default: () => [],
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    slaSettings: {
      type: Number,
      default: 0,
    },

    workflow: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      workflowCount: [
        {
          workflowStatus: "Total Requests",
          matchingKey: "totalRequests",
          visible: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "Completed Requests",
          matchingKey: "completedRequests",
          visible: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "Incomplete Requests",
          matchingKey: "incompleteRequests",
          visible: true,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "SLA Overdue",
          matchingKey: "slaOverdue",
          visible: true,
          additional: false,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "SLA Processing",
          matchingKey: "slaProcessing",
          visible: true,
          additional: false,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "SLA Achieved",
          matchingKey: "slaAchieved",
          visible: true,
          additional: false,
          totalCount: 0,
          dashboard: false,
        },
        {
          workflowStatus: "Forwarded",
          matchingKey: "forward",
          visible: true,
          totalCount: 0,
          dashboard: false,
        },
      ],
      barChartData: [],
      barChartOptions: {
        chart: {
          height: "85%",
        },
      },
      Highcharts: Highcharts,
      sankeyCharts: {
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          point: {
            valueDescriptionFormat:
              "{index}. {point.from} to {point.to}, {point.weight}.",
          },
        },
        series: [
          {
            keys: ["from", "to", "weight"],
            data: [],
            type: "sankey",
          },
        ],
      },
      sankeyChartsFilter: [],
      chartData: {
        chart: {
          type: "spline",
          height: "330px",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          categories: [],
          // categories: [
          //   "Jan",
          //   "Feb",
          //   "Mar",
          //   "Apr",
          //   "May",
          //   "Jun",
          //   "Jul",
          //   "Aug",
          //   "Sep",
          //   "Oct",
          //   "Nov",
          //   "Dec",
          // ],
        },
        yAxis: {
          title: {
            text: "Count",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#bfa0fe"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getWorkflowChartCount(this.workflowId, e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getWorkflowChartCount(
                        this.workflowId,
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          // {
          //   name: "Running",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0],
          // },
          // {
          //   name: "Completed",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21, 11],
          // },
        ],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      stageColumns: [
        {
          id: this.$nano.id(),
          name: "icon",
          label: "mdi-state-machine",
          type: "ICON",
        },
        {
          id: this.$nano.id(),
          name: "workflowStage",
          label: "Workflow Stages",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "currentInbox",
          label: "Current Inbox",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<15 Days",
          label: "<15 Days",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<30 Days",
          label: "<30 Days",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "<45 Days",
          label: "<45 Days",
          type: "SHORT_TEXT",
        },
      ],
      stageCount: [],
      userColumns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Assigned To",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "slaAchieved",
          label: "SLA Achieved",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "slaOverdue",
          label: "SLA Overdue",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "slaProcessing",
          label: "SLA Processing",
          type: "SHORT_TEXT",
        },
      ],
      userCount: [],
      timeChartxData: ["Less than 1 hour", "1-2 hours", "More than 2 hours"],
      timeChartyData: [],
      pieChartData: [],
      pieChartOptions: {
        chart: {
          height: "85%",
        },
      },
      options: {
        chart: {
          height: "85%",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      chartFilter: [],
      idToLabelMap: {},
      pinedchart: false,
      chartItem: {
        name: "monthlychart",
        description: "monthly chart",
      },
      stageItem: {
        name: "Stage Count",
        description: "Stage Count",
      },
      listedData: [],
      deleteData: {},
      deleteName: "",
      isDeleteConfirmationVisible: false,
      chartFilterBy: [],
      success: false,
      stagePin: false,
    };
  },

  watch: {
    workflowId: {
      deep: true,
      immediate: true,
      async handler() {
        await this.getFilterList();
        if (this.workflowId) {
          this.getWorkflowChartCount(this.workflowId);
        } else {
          this.getOverallCount();
          this.getAllWorkflowChartCount();
        }
      },
    },

    formFields: {
      deep: true,
      immediate: true,
      handler() {
        if (this.formFields.length) {
          let filterBy = [];
          filterBy = this.formFields
            .filter((field) => field.dataType === "SINGLE_SELECT")
            .map((field) => `${field.value}`);
          this.getChartByColumns(filterBy);
          this.chartFilterBy = filterBy;
          this.formFields.forEach((field) => {
            if (this.sankeyChartsFilter.length < 2) {
              if (field.dataType === "SINGLE_SELECT") {
                this.sankeyChartsFilter.push(field.value);
              }
            }
          });
          if (this.sankeyChartsFilter.length) {
            //this.overviewChart();
          }
        }
      },
    },

    workflow: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowId) {
          this.getWorkflowCount();
          this.getCountsByStage();
        }
      },
    },

    slaSettings: {
      deep: true,
      immediate: true,
      handler() {
        if (this.slaSettings === 2 || this.slaSettings === 4) {
          if (this.workflowId) {
            this.getUserWiseCounts();
            this.getProgressTiming();
          }
        }
      },
    },

    success: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.success) {
          await this.getFilterList();
          if (this.workflowId === 0) {
            await this.getOverallCount();
            await this.getAllWorkflowChartCount();
          } else {
            await this.getWorkflowCount();
            await this.getChartByColumns();
            await this.getWorkflowChartCount(this.workflowId);
            await this.getCountsByStage();
          }
        }
      },
    },
  },

  methods: {
    addSankeyChartsFilter() {
      this.sankeyChartsFilter.push("");
    },

    removeSankeyChartsFilter(index) {
      this.sankeyChartsFilter.splice(index, 1);
    },

    async getOverallCount() {
      this.$store.commit("showLoadingBar");
      this.workflowCount.forEach(
        (item) => (
          (item.visible = true), (item.totalCount = 0), (item.dashboard = false)
        )
      );
      const { error, payload } = await report.getOverallCount(0);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.slaSettings === 1 || this.slaSettings === 3) {
        this.workflowCount.forEach((item) => {
          if (item.additional) {
            item.visible = false;
          }
        });
      }
      if (this.workflowId === 0) {
        let List = this.listedData.map((filter) => ({
          id: this.$nano.id(),
          label: filter.name,
          filterJson: JSON.parse(filter.filterJson),
          dashboard: false,
        }));
        List.forEach((item) => {
          let id = item.filterJson[0].uId;
          let key = item.filterJson[0].key;
          let payloade = key in payload;
          if (id === this.workflowId && payloade) {
            this.workflowCount.forEach((workflowItem) => {
              if (workflowItem.matchingKey === key) {
                workflowItem.dashboard = true;
              }
            });
          }
        });
      }
      if (payload) {
        this.workflowCount.forEach((entry) => {
          const matchingKey = Object.keys(payload).find(
            (key) => key === entry.matchingKey
          );

          if (matchingKey in payload) {
            entry.totalCount = payload[matchingKey];
            entry.visible = true;
          } else {
            entry.visible = false;
          }
        });
      }
    },

    async getAllWorkflowChartCount() {
      this.pinedchart = false;
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.description,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));
      List.forEach((item) => {
        let type = item.filterJson[0].type;
        if (type === "fullchart") {
          this.pinedchart = true;
        }
      });
      this.$store.commit("showLoadingBar");
      this.chartData.series = [];
      let input = {
        year: new Date().getFullYear(),
        month: "",
        week: "",
      };
      const { error, payload } = await report.getAllWorkflowChart(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      // console.log("all-chart", payload);

      if (payload) {
        const series = payload.map((item) => {
          const category = Object.keys(item)[0];
          const seriesData = [];

          for (const status in item[category]) {
            const data = Object.entries(item[category][status]).map(
              ([month, value]) => ({
                name: month,
                y: parseInt(value),
                drilldown: true,
                category,
                status,
              })
            );
            seriesData.push({
              name: status,
              data,
            });
          }

          return {
            name: category,
            data: seriesData,
          };
        });
        console.log("all-chart-series", series);

        let lineCharts = this.$refs.refWorkflowChart;
        lineCharts.removeSeries();

        series.forEach((item) => {
          this.chartData.series = item;
          lineCharts.addSeries(this.chartData.series);
        });
      }
    },

    async getWorkflowCount() {
      this.$store.commit("showLoadingBar");
      this.workflowCount.forEach(
        (item) => (
          (item.visible = true), (item.totalCount = 0), (item.dashboard = false)
        )
      );
      const { error, payload } = await report.getWorkflowTicketCount(
        this.workflowId
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (this.slaSettings === 1 || this.slaSettings === 3) {
        this.workflowCount.forEach((item) => {
          if (item.additional) {
            item.visible = false;
          }
        });
      }
      let Listing = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));
      Listing.forEach((item) => {
        let id = item.filterJson[0].uId;
        let key = item.filterJson[0].key;
        let payloade = key in payload;
        if (id === this.workflowId && payloade) {
          this.workflowCount.forEach((workflowItem) => {
            if (workflowItem.matchingKey === key) {
              workflowItem.dashboard = true;
            }
          });
        }
      });
      if (payload) {
        this.workflowCount.forEach((entry) => {
          const matchingKey = Object.keys(payload).find(
            (key) => key === entry.matchingKey
          );

          if (matchingKey in payload) {
            entry.totalCount = payload[matchingKey];
            entry.visible = true;
          } else {
            entry.visible = false;
          }
        });
      }
    },

    async getWorkflowChartCount(id, month = "", weekno = "") {
      this.$store.commit("showLoadingBar");
      this.pinedchart = false;
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.description,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      List.forEach((item) => {
        let type = item.filterJson[0].type;
        if (
          item.filterJson[0].uId === this.workflowId &&
          type === "fullchart"
        ) {
          this.pinedchart = true;
        }
      });
      this.chartData.series = [];
      const weekNumber = weekno.replace(/\D/g, "");
      let input = {
        year: new Date().getFullYear(),
        month: month,
        weekno: weekNumber,
      };
      const { error, payload } = await report.getWorkflowChart(id, input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (this.workflowId !== 0) {
        let List = this.listedData.map((filter) => ({
          id: this.$nano.id(),
          label: filter.name,
          filterJson: JSON.parse(filter.filterJson),
          dashboard: false,
        }));
        List.forEach((item) => {
          let id = item.filterJson[0].uId;
          let key = item.filterJson[0].key;
          let payloade = key in payload;
          if (id === this.workflowId && payloade) {
            this.workflowCount.forEach((workflowItem) => {
              if (workflowItem.matchingKey === key) {
                workflowItem.dashboard = true;
              }
            });
          }
        });
      }

      if (payload) {
        if (payload.completed && payload.initiated) {
          let yAxis = [];
          this.chartData.xAxis.categories = Object.keys(payload.completed);

          yAxis.push({
            name: "Running",
            data: Object.values(payload.initiated).map(Number),
          });

          yAxis.push({
            name: "Completed",
            data: Object.values(payload.completed).map(Number),
          });

          this.chartData.series = yAxis;

          let lineCharts = this.$refs.refWorkflowChart;
          lineCharts.removeSeries();

          lineCharts.addSeries(this.chartData.series);
        }
      }
    },

    async getChartByColumns(filterBy) {
      this.chartFilter = filterBy;
      this.$store.commit("showLoadingBar");
      this.barChartData = [];
      this.pieChartData = [];
      let filters = [];
      if (this.chartFilterBy.length) {
        filters = this.chartFilterBy;
      } else {
        filters = filterBy;
      }
      const { error, payload } = await report.getChartByColumns(
        this.workflowId,
        filters
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        const idToLabelMap = this.formFields
          .filter((field) => field.dataType === "SINGLE_SELECT")
          .reduce((acc, field) => {
            acc[field.value] = field.label;
            return acc;
          }, {});
        this.idToLabelMap = idToLabelMap;
        const payloadWithLabels = Object.keys(payload).reduce((acc, key) => {
          const label = idToLabelMap[key.replace(/"/g, "")];
          if (label) {
            acc[label] = payload[key];
          }
          return acc;
        }, {});

        Object.keys(payload).forEach((category) => {
          const categoryName = category.replace(/"/g, "");

          payload[category].forEach((entry) => {
            entry.name = entry[categoryName] ? entry[categoryName] : "";
            delete entry[categoryName];
          });
        });

        Object.keys(payloadWithLabels).forEach((key) => {
          let xAxis = [],
            yAxisSeries1 = [],
            yAxisSeries2 = [];
          payloadWithLabels[key].forEach((row1) => {
            xAxis.push(row1.name);
            yAxisSeries1.push(row1.running);
            yAxisSeries2.push(row1.completed);
          });

          this.barChartData.push({
            key: `${key} - Report Chart`,
            xAxis: xAxis,
            yAxis: [
              {
                name: "Running",
                data: yAxisSeries1,
              },
              {
                name: "Completed",
                data: yAxisSeries2,
              },
            ],
          });
        });

        Object.keys(payloadWithLabels).forEach((key) => {
          payloadWithLabels[key].forEach((row) => {
            this.pieChartData.push({
              name: `${key} - ${row.name} - Report Chart`,
              xAxis: ["Running", "Completed"],
              yAxis: [row.running, row.completed],
            });
          });
        });
      }

      this.barChartData.forEach((barChartData) => {
        barChartData.dashboard = false;
      });

      this.pieChartData.forEach((pieChartData) => {
        pieChartData.dashboard = false;
      });

      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.description,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      List.forEach((item) => {
        console.log(item, "bar");
        let id = item.filterJson[0].uId;
        let type = item.filterJson[0].type;
        let keys = item.label;
        if (id === this.workflowId && type === "bar") {
          this.barChartData.forEach((barChartData) => {
            console.log(barChartData, "bar");
            if (barChartData.key === keys) {
              barChartData.dashboard = true;
            }
          });
        }
        if (id === this.workflowId && type === "Pie") {
          this.pieChartData.forEach((pieChartData) => {
            if (pieChartData.name === keys) {
              pieChartData.dashboard = true;
            }
          });
        }
      });
    },

    async getCountsByStage() {
      this.stagePin = false;
      this.stageCount = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await report.getCountsByStage(this.workflowId);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));
      List.forEach((item) => {
        let id = item.filterJson[0].uId;

        if (id === this.workflowId && item.filterJson[0].type === "stage") {
          this.stagePin = true;
        }
      });
      this.workflowStage.forEach((stage) => {
        const existingEntry = this.stageCount.find(
          (entry) => entry.workflowStage === stage.label
        );

        if (!existingEntry && stage.type === "START") {
          this.stageCount.push({
            stageId: stage.key,
            workflowStage: stage.label,
            icon: stage.icon,
            type: stage.type,
            color: stage.color,
            currentInbox: 0,
            ["<15 Days"]: 0,
            ["<30 Days"]: 0,
            ["<45 Days"]: 0,
          });
        }
      });

      this.workflowStage.forEach((stage) => {
        const existingEntry = this.stageCount.find(
          (entry) => entry.workflowStage === stage.label
        );

        if (!existingEntry && stage.type !== "START" && stage.type !== "END") {
          this.stageCount.push({
            stageId: stage.key,
            workflowStage: stage.label,
            icon: stage.icon,
            type: stage.type,
            color: stage.color,
            currentInbox: 0,
            ["<15 Days"]: 0,
            ["<30 Days"]: 0,
            ["<45 Days"]: 0,
          });
        }
      });

      this.workflowStage.forEach((stage) => {
        const existingEntry = this.stageCount.find(
          (entry) => entry.workflowStage === stage.label
        );

        if (!existingEntry && stage.type === "END") {
          this.stageCount.push({
            stageId: stage.key,
            workflowStage: stage.label,
            icon: stage.icon,
            type: stage.type,
            color: stage.color,
            currentInbox: 0,
            ["<15 Days"]: 0,
            ["<30 Days"]: 0,
            ["<45 Days"]: 0,
          });
        }
      });

      if (payload) {
        this.workflowStage.forEach((stage) => {
          const matchingKey = payload.find(
            (item) => stage.key === item.activityId
          );
          matchingKey.label = stage.label;

          this.stageCount.forEach((countItem) => {
            if (matchingKey.label === countItem.workflowStage) {
              countItem.currentInbox += matchingKey.currentInbox || 0;
              countItem["<15 Days"] += matchingKey["< 15 Days"] || 0;
              countItem["<30 Days"] += matchingKey["< 30 Days"] || 0;
              countItem["<45 Days"] += matchingKey["< 45 Days"] || 0;
            }
          });
        });
      }
    },

    async getUserWiseCounts() {
      this.userCount = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await report.getUserWiseCounts(
        this.workflowId
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        payload.forEach((user) => {
          this.userCount.push({
            name: user.name,
            slaAchieved: user.slaAchieved,
            slaAchievedRequestNo: user.slaAchievedRequestNo,
            slaOverdue: user.slaOverdue,
            slaOverdueRequestNo: user.slaOverdueRequestNo,
            slaProcessing: user.slaProcessing,
            slaProcessingRequestNo: user.slaProcessingRequestNo,
          });
        });
      }
    },

    async getProgressTiming() {
      this.$store.commit("showLoadingBar");
      this.timeChartyData = [];
      const { error, payload } = await report.getProgressTiming(
        this.workflowId
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        payload.forEach((time) => {
          const lessThanOne = parseFloat(time["<1hour"].replace(/[^0-9]/g, ""));

          const oneOrTwo = parseFloat(time["1-2hours"].replace(/[^0-9]/g, ""));

          const moreThanTwo = parseFloat(time[">2hour"].replace(/[^0-9]/g, ""));

          this.timeChartyData.push({
            data: [lessThanOne, oneOrTwo, moreThanTwo],
          });
        });
      }
    },

    async overviewChart() {
      this.$store.commit("showLoadingBar");
      try {
        this.$refs.refCharts.removeSeries();
      } catch (e) {
        //
      }
      const { error, payload } = await workflow.overviewChart(
        this.workflowId,
        this.sankeyChartsFilter
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.sankeyCharts.series[0].data = payload;
        this.sankeyCharts.series[0].name = workflow.name;
        this.$refs.refCharts.addSeries(this.sankeyCharts.series[0]);
      }
    },

    async overviewTableReport() {
      this.columns = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.overviewTableReport(
        this.workflowId,
        this.filter
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.Table.length) {
          this.tableReport = payload.Table;
          this.tableReportData = this.tableReport;
          let keys = Object.keys(this.tableReport[0]);
          keys.forEach((key) => {
            this.columns.push({
              id: this.$nano.id(),
              name: key,
            });
          });
        }
      }
    },

    async saveFilter(item, type) {
      console.log(this.workflow, item, "work");

      this.success = false;
      let filter = JSON.stringify(this.filterBy);
      let filtering = [
        {
          uId: this.workflowId,
          type: type,
          chartFilter: this.chartFilterBy,
          key: item.matchingKey,
          formField: this.idToLabelMap,
          filters: filter || "[]",
          stage: this.workflowStage,
        },
      ];
      let filterss = JSON.stringify(filtering);
      let userId = [this.$store.state.session.id];
      let name = "";
      let description = "";
      if (this.workflowId !== 0) {
        if (type === "Pie") {
          name = this.workflow.name + "  " + item.name;
          description = item.name;
        } else if (type === "bar") {
          name = this.workflow.name + "  " + item.key;
          description = item.key;
        } else if (type === "reportCount") {
          name = this.workflow.name + "  " + item.workflowStatus;
          description = item.workflowStatus;
        } else if (type === "fullchart") {
          name = this.workflow.name + "  " + item.name;
          description = item.name;
        } else if (type === "stage") {
          name = this.workflow.name + "  " + item.name;
          description = item.description;
        }
      } else if (this.workflowId === 0) {
        if (type === "fullchart") {
          name = "All" + "  " + "Monthly Chart";
          description = "Monthly Chart";
        } else {
          name = "All" + "  " + item.workflowStatus;
          description = item.workflowStatus;
        }
      }
      let input = {
        name: name,
        description: description,
        filterJson: filterss,
        filterFrom: "workflowReportChart",
        pinDashboard: true,
        assignTo: JSON.stringify(userId),
      };
      console.log(input, "input");
      const { error } = await dashboard.saveDashboard(input);
      if (error) {
        this.$alert.warning("The Name Already Registered ");
        return;
      }
      this.$alert.success(name + "  " + "Pinned SuccessFully");
      await this.getFilterList();
      if (this.workflowId === 0) {
        await this.getOverallCount();
        await this.getAllWorkflowChartCount();
      } else {
        await this.getWorkflowCount();
        await this.getChartByColumns();
        await this.getWorkflowChartCount(this.workflowId);
        if (type === "stage") {
          await this.getCountsByStage();
        }
      }
    },
    async getFilterList() {
      this.loadingBarContent = true;
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "workflowReportChart",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
              {
                criteria: "filterJson",
                condition: "CONTAINS",
                value: '"uId":' + this.workflowId + ",",
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.loadingBarContent = false;
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      let data = payload.data[0].value;
      this.listedData = data;

      console.log(this.listedData, "loisted");
    },

    deleteItem(value, type) {
      this.success = false;
      this.deleteData = {};
      this.deleteName = "";
      let names = "";
      console.log(value, "values");
      if (this.workflowId !== 0) {
        if (type === "Pie") {
          names = this.workflow.name + "  " + value.name;
        } else if (type === "bar") {
          names = this.workflow.name + "  " + value.key;
        } else if (type === "reportCount") {
          names = this.workflow.name + "  " + value.workflowStatus;
        } else if (type === "fullchart") {
          names = this.workflow.name + "  " + value.name;
        } else if (type === "stage") {
          names = this.workflow.name + "  " + value.name;
        }
      } else if (this.workflowId === 0) {
        if (type === "fullchart") {
          names = "All" + "  " + "Monthly Chart";
        } else {
          names = "All" + "  " + value.workflowStatus;
        }
      }
      this.deleteName = names;
      this.listedData.map((item) => {
        const parse = JSON.parse(item.filterJson);
        if (parse[0].uId === this.workflowId && item.name === names) {
          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  .workflowCharts,
  .workflowReports {
    // height: calc(100vh - 235px);
    height: 100%;
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 10px;
    background: white;
    width: 100%;
    .chart {
      height: 560px !important;
    }

    .border-bottom {
      border-bottom: 1px solid var(--divider-color);
    }
  }

  .link-style {
    cursor: pointer;
    text-decoration: none;
  }

  .link-style:hover {
    text-decoration: underline;
  }

  .wrapper {
    position: relative;
    height: calc(100vh - 208px);
  }

  .chart {
    height: 405px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    &.chartBox {
      height: 100px;
    }
  }

  .header {
    text-anchor: start;
    font-size: 15px;
    font-weight: 700;
    fill: rgb(55, 61, 63);
  }

  .header-top {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .label {
    color: #a391cf !important;
  }

  .count {
    color: #8162cc;
    font-weight: 800;
    font-size: 24px;
  }

  #stage {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);

    #stageTable {
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }
    }
  }

  #user {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #userTable {
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        border-bottom: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      tr:nth-child(odd) td {
        background-color: #f5effa; /* Set the background color for odd rows */
      }

      tr:nth-child(even) td {
        background-color: #eafcff; /* Set the background color for even rows */
      }

      tr:first-child th,
      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      td:first-child,
      th:first-child {
        border-left: 1px solid var(--divider-color); /* Remove left border from the first column */
      }

      td:last-child,
      th:last-child {
        border-right: 1px solid var(--divider-color); /* Remove right border from the last column */
      }
    }
  }

  #workflow {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #workflowTable {
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        border-bottom: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child th,
      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      td:first-child,
      th:first-child {
        border-left: 1px solid var(--divider-color); /* Remove left border from the first column */
      }

      td:last-child,
      th:last-child {
        border-right: 1px solid var(--divider-color); /* Remove right border from the last column */
      }
    }
  }
}
</style>
