<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    :report="true"
    @select="handleOpenFile"
  >
    <template #actions="{ item }">
      <div v-if="filterList" style="width: 100px" class="row">
        <BaseActionButton
          v-if="filterList"
          is-flat
          no-border
          icon="eva-edit-outline"
          color="secondary"
          class="col-auto q-pa-sm q-mr-sm"
          @click.stop="$emit('isSavesheet', item.id)"
        />
        <BaseActionButton
          v-if="filterList"
          is-flat
          no-border
          icon="eva-trash-2-outline"
          color="primary"
          class="col-auto q-pa-sm"
          @click.stop="$emit('isDelete', item.id)"
        />
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "ReportList",

  components: { ItemList },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
    filterList: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleOpenFile(itemId, item) {
      this.$emit("view", itemId, item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
