<template>
  <div class="goto-reports q-mt-sm">
    <BaseScrollbar height="calc(100vh - 265px)">
      <div class="row">
        <div class="col-6 q-mb-sm">
          <div class="q-mr-xs card">
            <BarChart
              :title="fieldLabel"
              is-stacked
              :x-data="barChartxData"
              :y-data="barChartyData"
              :options="barChartOptions"
              class="q-mr-xs"
            >
              <template #header>
                <div class="row">
                  <div class="text-bold" style="color: #a48965">
                    {{ selectedTitle }}
                  </div>
                </div>
              </template>
            </BarChart>
          </div>
        </div>

        <div class="col-6 q-mb-sm">
          <div class="q-ml-xs card q-pa-sm">
            <div class="q-mb-sm text-bold">Last Excel Import Report</div>
            <table class="tbl-import-status">
              <tbody>
                <tr>
                  <td class="text-bold text-secondary">Import Date</td>
                  <td>{{ masterFileProcessTable.importDate || "-" }}</td>
                </tr>
                <tr>
                  <td class="text-bold text-secondary">Total Imported Rows</td>
                  <td>{{ masterFileProcessTable.totalRows || "-" }}</td>
                </tr>
                <tr>
                  <td class="text-bold text-secondary">
                    Newly Discovered Resource IDs
                  </td>
                  <td>{{ masterFileProcessTable.inserted || "-" }}</td>
                </tr>
                <tr>
                  <td class="text-bold text-secondary">Resource IDs Updated</td>
                  <td>{{ masterFileProcessTable.updated || "-" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-12 q-mb-sm">
          <div class="row q-col-gutter-sm">
            <div class="col-3">
              <div class="card chartBox row">
                <div class="col-12 text-bold">
                  {{ fieldLabel }}
                  <span style="color: #a48965">{{ selectedTitle }}</span>
                </div>
                <div class="col-6">
                  <q-chip outline color="red" class="chips red-hover">
                    Terminate
                  </q-chip>
                </div>
                <div class="col-6">
                  <div class="column">
                    <div class="col self-center">
                      Spend: $ {{ terminate.spend }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="column">
                    <div class="col self-center q-mt-sm count">
                      $ {{ terminate.recommendation }}
                    </div>
                    <div class="col self-center">Potential Savings</div>
                  </div>
                </div>
                <BaseSeparator is-vertical has-left-inset />
                <div class="col-4">
                  <div class="column">
                    <div class="col self-center q-mt-sm count">
                      $ {{ terminate.savings }}
                    </div>
                    <div class="col self-center">Actual Savings</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="card chartBox row">
                <div class="col-12 text-bold">
                  {{ fieldLabel }}
                  <span style="color: #a48965">{{ selectedTitle }}</span>
                </div>
                <div class="col-6">
                  <q-chip outline color="orange" class="chips orange-hover">
                    Ignore
                  </q-chip>
                </div>
                <div class="col-6">
                  <div class="column">
                    <div class="col self-center">
                      Spend: $ {{ ignore.spend }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="column">
                    <div class="col self-center q-mt-sm count">
                      $ {{ ignore.recommendation }}
                    </div>
                    <div class="col self-center">Potential Savings</div>
                  </div>
                </div>
                <BaseSeparator is-vertical has-left-inset />
                <div class="col-4">
                  <div class="column">
                    <div class="col self-center q-mt-sm count">
                      $ {{ ignore.savings }}
                    </div>
                    <div class="col self-center">Actual Savings</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="card chartBox row">
                <div class="col-12 text-bold">
                  {{ fieldLabel }}
                  <span style="color: #a48965">{{ selectedTitle }}</span>
                </div>
                <div class="col-6">
                  <q-chip outline color="green" class="chips green-hover">
                    Rightsize
                  </q-chip>
                </div>
                <div class="col-6">
                  <div class="column">
                    <div class="col self-center">
                      Spend: $ {{ rightSize.spend }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="column">
                    <div class="col self-center q-mt-sm count">
                      $ {{ rightSize.recommendation }}
                    </div>
                    <div class="col self-center">Potential Savings</div>
                  </div>
                </div>
                <BaseSeparator is-vertical has-left-inset />
                <div class="col-4">
                  <div class="column">
                    <div class="col self-center q-mt-sm count">
                      $ {{ rightSize.savings }}
                    </div>
                    <div class="col self-center">Actual Savings</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="card chartBox row">
                <div class="col-12 text-bold">
                  {{ fieldLabel }}
                  <span style="color: #a48965">{{ selectedTitle }}</span>
                </div>
                <div class="col-6">
                  <q-chip outline color="pink" class="chips pink-hover">
                    Exception
                  </q-chip>
                </div>
                <div class="col-6">
                  <div class="column">
                    <div class="col self-center">
                      Spend: $ {{ exception.spend }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="column">
                    <div class="col self-center q-mt-sm count">
                      $ {{ exception.recommendation }}
                    </div>
                    <div class="col self-center">Potential Savings</div>
                  </div>
                </div>
                <BaseSeparator is-vertical has-left-inset />
                <div class="col-4">
                  <div class="column">
                    <div class="col self-center q-mt-sm count">
                      $ {{ exception.savings }}
                    </div>
                    <div class="col self-center">Actual Savings</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="tblCostSavings">
          <div id="spending" class="col-12">
            <div class="row">
              <div class="text-bold">Cost Savings</div>
              <q-space />
              <!-- <div id="filter" class="description"></div> -->
              <SelectField
                v-if="false"
                v-model="fieldValue"
                :options="formFields"
                :is-clearable="true"
                class="custom-height q-mr-sm q-mb-sm"
              >
              </SelectField>

              <BaseActionButton
                v-tooltip.top="'Export'"
                is-flat
                no-border
                icon="mdi-table-arrow-right"
                @click="export_Data"
              />

              <BaseActionButton
                v-tooltip.top="'Refresh'"
                icon="eva-refresh"
                color="gray"
                no-border
                is-flat
                size="20px"
                @click="getTotalSpendAndSavings"
              />
            </div>

            <BaseScrollbar height="340px">
              <table id="spendingTable">
                <thead>
                  <tr>
                    <!-- columns -->

                    <th
                      v-for="column in tblCostSavingsColumns"
                      :key="column.id"
                    >
                      {{ column.label }}
                    </th>

                    <!-- ... -->
                  </tr>
                </thead>

                <tbody>
                  <template v-for="(item, index) in tblCostSavings">
                    <tr
                      :key="item.id"
                      :style="{
                        backgroundColor: index === 0 ? '#dcf7e3' : 'inherit',
                      }"
                    >
                      <!-- columns -->

                      <td
                        v-for="column in tblCostSavingsColumns"
                        :key="column.id"
                      >
                        <slot
                          :name="column.name"
                          :value="item[column.name]"
                          :item="item"
                        >
                          <template
                            v-if="
                              column.name === 'title' &&
                              item[column.name] !== 'total'
                            "
                          >
                            <div class="title" @click="getData(item)">
                              {{ item[column.name] }}
                            </div>
                          </template>

                          <template v-else>
                            {{ item[column.name] }}
                          </template>
                        </slot>
                      </td>

                      <!-- ... -->
                    </tr>
                  </template>
                </tbody>
              </table>
            </BaseScrollbar>
          </div>
        </template>

        <div class="col-12 q-mt-sm">
          <div class="card treemap-chart">
            <TreeMapChart
              title="Region Chart"
              :xy-data="treeMapChartxyData"
              :options="options"
            >
              <template #header>
                <div class="row">
                  <SelectField
                    v-if="false"
                    v-model="region"
                    :options="formFields"
                    :is-clearable="true"
                    class="custom-height q-mr-md q-mb-sm"
                    @input="getCloudabilityByTitle"
                  />
                </div>
              </template>
            </TreeMapChart>
          </div>
        </div>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
import BarChart from "@/components/common/charts/BarChart.vue";
import TreeMapChart from "@/components/common/charts/TreeMapChart.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { report } from "@/api/factory.js";
import { exportData } from "@/helpers/export-excel-data";

export default {
  name: "GotoReportChart",

  components: { BarChart, TreeMapChart, SelectField },

  props: {
    workflowId: {
      type: Number,
      default: 0,
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    refresh: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      barChartxData: ["Spend", "Potential Savings", "Actual Savings"],
      barChartyData: [],
      treeMapChartxyData: [
        {
          name: "Population",
          data: [],
        },
      ],
      tblCostSavingsColumns: [
        {
          id: this.$nano.id(),
          name: "title",
          label: "Title",
        },
        {
          id: this.$nano.id(),
          name: "counts",
          label: "Count",
        },
        {
          id: this.$nano.id(),
          name: "Spend",
          label: "Total Spend",
        },
        {
          id: this.$nano.id(),
          name: "Savings",
          label: "Potential Savings",
        },
        {
          id: this.$nano.id(),
          name: "savingsPotential",
          label: "Actual Savings",
        },
      ],
      tblCostSavings: [],
      options: {
        chart: {
          height: "570px",
        },
        tooltip: {
          custom: function ({ dataPointIndex, w }) {
            let str =
              '<div class="arrow_box">' +
              "<div class='title'>" +
              w.config.series[0].data[dataPointIndex].x +
              "</div>" +
              "<table><tbody>" +
              "<tr><td>Total Spend</td><td>" +
              w.config.series[0].data[dataPointIndex].spend +
              "</td></tr>" +
              "<tr><td>Potential Savings</td><td>" +
              w.config.series[0].data[dataPointIndex].potentialSavings +
              "</td></tr>" +
              "<tr><td>Actual Savings</td><td>" +
              w.config.series[0].data[dataPointIndex].actualSavings +
              "</td></tr>" +
              "</tbody></table>" +
              "</div>";
            return str;
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (text, op) {
            // console.log(text);
            // console.log(op.w.config.series[0].data[op.seriesIndex].spend);
            return [text, op.value];
          },
        },
      },
      barChartOptions: {
        chart: {
          height: "85%",
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return "$" + val / 1000 + "K";
            },
          },
        },
      },
      fieldLabel: "",
      fieldValue: "",
      region: "",
      terminate: {
        title: "Terminate",
        spend: 0,
        recommendation: 0,
        savings: 0,
      },
      ignore: {
        title: "Ignore",
        spend: 0,
        recommendation: 0,
        savings: 0,
      },
      rightSize: {
        title: "Rightsize",
        spend: 0,
        recommendation: 0,
        savings: 0,
      },
      exception: {
        title: "Exception",
        spend: 0,
        recommendation: 0,
        savings: 0,
      },
      selectedTitle: "",
      masterFileProcessTable: {},
    };
  },

  computed: {},

  watch: {
    workflowId: {
      deep: true,
      immediate: true,
      handler() {
        this.pageInit();
      },
    },

    formFields: {
      deep: true,
      immediate: true,
      handler() {
        if (this.formFields.length) {
          this.fieldValue = this.formFields.find(
            (field) => field.label === "Account Name"
          )?.value;
          this.getTotalSpendAndSavings();
          this.getCloudabilityByTitle();
        }
      },
    },

    fieldValue: {
      deep: true,
      immediate: true,
      handler() {
        if (this.fieldValue) {
          let field = this.formFields.find(
            (field) => field.value === this.fieldValue
          );
          if (field) {
            this.fieldLabel = field.label;
          }
          this.selectedTitle = "";
        }
      },
    },

    refresh: {
      deep: true,
      immediate: true,
      handler() {
        if (this.refresh) {
          this.$emit("refresh", false);
          this.pageInit();
          this.getTotalSpendAndSavings();
          this.getCloudabilityByTitle();
        }
      },
    },
  },

  methods: {
    pageInit() {
      this.getOverallAndRecommendation();
      this.getMasterFileProcess();
    },

    export_Data() {
      if (this.tblCostSavings.length) {
        exportData(this.tblCostSavings);
      }
    },

    getData(item) {
      this.selectedTitle = item.title;
      this.barChartyData[0].data = [
        item.totalSpend,
        item.totalSavingsRecommendation,
        item.savingsPotential,
      ];
      this.terminate = {
        title: "Terminate",
        spend: 0,
        recommendation: 0,
        savings: 0,
      };
      this.rightSize = {
        title: "Rightsize",
        spend: 0,
        recommendation: 0,
        savings: 0,
      };
      this.ignore = {
        title: "Ignore",
        spend: 0,
        recommendation: 0,
        savings: 0,
      };
      this.exception = {
        title: "Exception",
        spend: 0,
        recommendation: 0,
        savings: 0,
      };
      this.getSelectedOverallAndRecommendation();
    },

    async getOverallAndRecommendation() {
      this.barChartyData = [];
      this.$store.commit("showLoadingBar");
      let input = {
        title: "",
        value: "",
      };
      const { error, payload } = await report.getOverallAndRecommendation(
        this.workflowId,
        input
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        payload.forEach((value) => {
          if (value.recommendation == "total") {
            this.barChartyData.push({
              name: ["Total"],
              data: [
                value.totalSpend,
                value.totalSavingsRecommendation,
                value.totalSavingsPotential,
              ],
            });
          } else if (value.recommendation.toLowerCase() == "terminate") {
            this.terminate.spend = value.totalSpend;
            this.terminate.recommendation = value.totalSavingsRecommendation;
            this.terminate.savings = value.totalSavingsPotential;
          } else if (value.recommendation.toLowerCase() == "ignore") {
            this.ignore.spend = value.totalSpend;
            this.ignore.recommendation = value.totalSavingsRecommendation;
            this.ignore.savings = value.totalSavingsPotential;
          } else if (value.recommendation.toLowerCase() == "rightsize") {
            this.rightSize.spend = value.totalSpend;
            this.rightSize.recommendation = value.totalSavingsRecommendation;
            this.rightSize.savings = value.totalSavingsPotential;
          } else if (value.recommendation.toLowerCase() == "exception") {
            this.exception.spend = value.totalSpend;
            this.exception.recommendation = value.totalSavingsRecommendation;
            this.exception.savings = value.totalSavingsPotential;
          }
        });
      }
    },

    async getTotalSpendAndSavings() {
      this.$store.commit("showLoadingBar");
      this.tblCostSavings = [];
      let spendFieldId = "",
        savingsFieldId = "";
      spendFieldId = this.formFields.find(
        (field) => field.label === "Spend"
      )?.value;
      savingsFieldId = this.formFields.find(
        (field) => field.label === "Savings"
      )?.value;
      let input = {
        title: this.fieldValue,
        columns: [spendFieldId, savingsFieldId],
        sortBy: { criteria: spendFieldId, order: "DESC" },
      };
      const { error, payload } = await report.getTotalSpendAndSavings(
        this.workflowId,
        input
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        this.tblCostSavings = payload;
      }
    },

    async getCloudabilityByTitle() {
      this.treeMapChartxyData[0].data = [];
      this.$store.commit("showLoadingBar");
      let accountNameFieldId = "",
        spendFieldId = "",
        savingsFieldId = "";
      accountNameFieldId = this.formFields.find(
        (field) => field.label === "Account Name"
      )?.value;
      spendFieldId = this.formFields.find(
        (field) => field.label === "Spend"
      )?.value;
      savingsFieldId = this.formFields.find(
        (field) => field.label === "Savings"
      )?.value;

      if (accountNameFieldId) {
        let input = {
          title: accountNameFieldId,
          columns: [spendFieldId, savingsFieldId],
          sortBy: { criteria: spendFieldId, order: "DESC" },
        };
        const { error, payload } = await report.getTotalSpendAndSavings(
          this.workflowId,
          input
        );
        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }

        if (payload) {
          payload.forEach((value) => {
            if (value.title != "total") {
              this.treeMapChartxyData[0].data.push({
                x: value.title,
                y: value.counts,
                spend: value.Spend,
                potentialSavings: value.Savings,
                actualSavings: value.savingsPotential,
              });
            }
          });
        }
      }
    },

    async getSelectedOverallAndRecommendation() {
      this.$store.commit("showLoadingBar");
      let input = {
        title: this.fieldValue,
        value: this.selectedTitle,
      };
      const { error, payload } = await report.getOverallAndRecommendation(
        this.workflowId,
        input
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        payload.forEach((value) => {
          if (value.recommendation.toLowerCase() == "terminate") {
            this.terminate.spend = value.totalSpend;
            this.terminate.recommendation = value.totalSavingsRecommendation;
            this.terminate.savings = value.totalSavingsPotential;
          } else if (value.recommendation.toLowerCase() == "ignore") {
            this.ignore.spend = value.totalSpend;
            this.ignore.recommendation = value.totalSavingsRecommendation;
            this.ignore.savings = value.totalSavingsPotential;
          } else if (value.recommendation.toLowerCase() == "rightsize") {
            this.rightSize.spend = value.totalSpend;
            this.rightSize.recommendation = value.totalSavingsRecommendation;
            this.rightSize.savings = value.totalSavingsPotential;
          } else if (value.recommendation.toLowerCase() == "exception") {
            this.exception.spend = value.totalSpend;
            this.exception.recommendation = value.totalSavingsRecommendation;
            this.exception.savings = value.totalSavingsPotential;
          }
        });
      }
    },

    async getMasterFileProcess() {
      this.masterFileProcessTable = {};
      const { error, payload } = await report.getMasterFileProcess(
        this.workflowId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.masterFileProcessTable = payload;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.goto-reports {
  position: relative;
  //   height: calc(100vh - 248px);

  .card {
    height: 300px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    &.chartBox {
      height: 150px;
      padding: 10px;
    }

    &.treemap-chart {
      height: 560px;
    }

    table {
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
      }

      tr:not(:first-child) td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      td:first-child {
        border-right: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      .bold {
        font-weight: bold;
      }
    }
  }

  #spending {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #filter {
      border-radius: 4px;
      border-style: none solid none none;
      border: 1px solid var(--divider-color);

      .description {
        color: var(--icon-color-inverted);
        margin-bottom: 4px;
      }
    }

    #spendingTable {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 15px;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
          position: sticky;
          top: 0;
          background-color: var(--body-bg-color);
          z-index: 2;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        .title {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      tbody {
        tr:first-child {
          position: sticky;
          top: 36px;
          background-color: #dcf7e3;
          z-index: 1;
        }
      }
    }
  }

  .chips {
    background-color: #fff !important;
    margin: 0px !important;
    margin-right: 10px !important;
    // margin-bottom: 10px !important;
    font-size: 12px !important;
  }

  .tbl-import-status {
    td {
      width: 50%;
    }
  }
}
</style>
<style lang="scss">
#select-field.custom-height {
  .select {
    color: $secondary !important;
    width: 140px !important;
    overflow: hidden !important;
  }

  #field-wrapper {
    height: 30px;
  }
}

.arrow_box {
  padding: 10px;
  .title {
    padding-left: 10px;
    padding-bottom: 5px;
    font-weight: bold;
    color: $primary;
    font-size: 16px;
  }

  table {
    border-radius: 10px;
    width: 100%;
    border: 1px solid var(--divider-color);

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    tr:not(:last-child) td {
      border-bottom: 1px solid var(--divider-color);
    }

    td {
      padding: 8px;
      font-weight: 500;
    }

    td:first-child {
      color: var(--secondary);
      border-right: 1px solid var(--divider-color);
    }
  }
}
</style>
