<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="red"
    @input="handleInput"
  >
    <template #title>Delete saved Items</template>

    <template #default>
      <div class="q-py-md">
        <p>
          Are you sure you want to delete the save search
          <b>"{{ deleteData.name }}"</b>. If you delete the save search, you
          will not be able to use this .
        </p>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="red"
        @click="handleNo"
      />
      <BaseButton label="yes" color="red" @click="deleteFilter" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";
import { dashboard } from "@/api/factory.js";

export default {
  name: "ConfirmDeleteRepository",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    deleteData: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    async deleteFilter() {
      const { error } = await dashboard.deleteFilter(
        this.deleteData.id,
        this.deleteData
      );
      if (error) {
        this.$alert.warning("error");
        return;
      }

      this.$emit("input", false);
      this.$emit("delete");
      this.$alert.success("Save search deleted successfully");
    },
  },
};
</script>

<style lang="scss" scoped></style>
